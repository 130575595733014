<script setup>
  import { computed, ref } from 'vue';

  const props = defineProps({
    item: Object,
    depth: Number,
    maxDepth: { type: Number, default: 3 },
    category: Boolean,
  });

  const visible = ref(false);

  const component = computed(() => {
    if (props.item.url) {
      return 'VisitorLink';
    } else {
      return 'span';
    }
  });

  const specific = computed(() => {
    if (props.item.url) {
      return {
        href: props.item.url || undefined,
        rel: props.item.rel || undefined,
        target: props.item.target || undefined,
        explicit: props.item.explicit,
      };
    } else {
      return {
        ['data-cc']: props.item.dataCc || undefined,
      };
    }
  });

  const level = computed(() => {
    return `level-${props.depth}`;
  });

  const onMouseEnter = () => {
    visible.value = true;
  };

  const onMouseLeave = () => {
    visible.value = false;
  };
</script>

<template>
  <li
    :class="level"
    class="nav-item"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <component :is="component" :class="{ category }" v-bind="specific">
      <span @click="$emit('close-menu')">
        {{ item.name }}
        <small v-if="depth !== 0" class="nav-item__available">
          ({{ item.available }})
        </small>
      </span>

      <IconArrow v-if="depth === 0 && item.children.length > 0" />
    </component>
    <div v-if="category || item.children.length > 0" :class="{ 'nav-item__children--visible': visible }" class="nav-item__children">
      <span class="nav-item__name">{{ item.name }}</span>
      <ul class="nav-item__sublist">
        <HeaderNavItem v-for="item in item.children" :depth="depth + 1" :item="item" />
      </ul>
    </div>
  </li>
</template>

<style lang="scss" scoped>
  .nav-item {
    padding: 0.5rem 0;
    width: 100%;
    white-space: nowrap;

    &__available {
      color: var(--color-gray);
      font-size: 0.75rem;
      font-weight: 400;
    }

    a {
      display: block;
      padding: 0.25rem 0;
    }

    &__sublist {
      list-style: none;
      margin-top: 1.5rem;
    }

    @media (max-width: 992px) {
      padding: 0.25rem 0;
    }
  }

  .level-1 {
    .nav-item__children {
      position: absolute;
      display: none;
      top: 0;
      left: 300px;
      width: 70%;
      height: 90%;
      padding: 0.5rem 0 0 3.5rem;
      border-left: 2px solid var(--primary-color);

      @media (max-width: 1200px) {
        left: 260px;
      }
    }

    .nav-item__name {
      color: var(--primary-color);
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 0.28px;
    }

    .nav-item__children--visible {
      display: block;
      margin-top: 2rem;

      .nav-item__sublist {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 80%;
      }
    }

    .level-2 {
      .nav-item__children {
        display: none;
      }
    }
  }
</style>
